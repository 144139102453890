// TelegramWebApp.js

import React, { useEffect, useState } from "react";
import { retrieveLaunchParams } from "@telegram-apps/sdk";
import { useUserContext } from "../components/UserContext";
import { finalizeLogin } from "../utils/authUtils";
import Loader from "../components/Loader";
import useNavigateOnAuth from "../hooks/useNavigateOnAuth";

function TelegramWebApp({ handleAuthentication }) {
  const [error, setError] = useState(null);

  // Context and utils for setting user state
  const {
    setUserId,
    setUserName,
    setUserAvatar,
    setUserFollows,
    setUserFollowers,
  } = useUserContext();

  // Use the custom hook to handle navigation on authentication
  useNavigateOnAuth();

  useEffect(() => {
    let initDataRaw;

    try {
      const params = retrieveLaunchParams();
      initDataRaw = params.initDataRaw;
    } catch (err) {
      console.warn("retrieveLaunchParams() failed:", err);
      // Fallback to window.Telegram if available
      if (
        window.Telegram &&
        window.Telegram.WebApp &&
        window.Telegram.WebApp.initDataRaw
      ) {
        initDataRaw = window.Telegram.WebApp.initDataRaw;
      } else {
        initDataRaw = "";
      }
    }

    const initDataToSend = initDataRaw || "";

    fetch("/api/telegram/verify-initdata/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ initData: initDataToSend }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // Use finalizeLogin just like handleSignIn does
          finalizeLogin(
            data,
            handleAuthentication,
            setUserId,
            setUserName,
            setUserAvatar,
            setUserFollows,
            setUserFollowers
          );
        } else {
          setError(`Backend error: ${data.error}`);
        }
      })
      .catch((err) => {
        setError(`Fetch error: ${err.message}`);
      });
  }, []);

  if (error) {
    return (
      <div className="main-content" style={{ color: "red" }}>
        <h2>Error:</h2>
        <p>{error}</p>
      </div>
    );
  }

  // If no error, we're either loading or have already navigated away after successful login.
  // Show a loading indicator:
  return (
    <div className="main-content">
      <Loader />
    </div>
  );
}

export default TelegramWebApp;
