// SignIn.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import handleSignIn from "../utils/authUtils";
import { useUserContext } from "../components/UserContext";
import ImageWithLoader from "../components/ImageWithLoader";
import { useNotifications } from "../contexts/NotificationsContext";
import logo from "../assets/icon.webp";
import useNavigateOnAuth from "../hooks/useNavigateOnAuth";

const SignInForm = ({ handleAuthentication }) => {
  const {
    setUserId,
    setUserName,
    setUserAvatar,
    setUserFollows,
    setUserFollowers,
  } = useUserContext();

  const { setNotifications } = useNotifications();

  // Use the custom hook to handle navigation on authentication
  useNavigateOnAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // Define onSubmit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await handleSignIn(
        username,
        password,
        handleAuthentication,
        setUserId,
        setUserName,
        setUserAvatar,
        setUserFollows,
        setUserFollowers,
        setNotifications
      );
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="signin-container">
      <div className="info-section">
        <ImageWithLoader src={logo} alt="Cavegram Logo" imageClassName="logo" />
        <h1 className="service-name">
          Cavegram
          <span className="pre-alpha">pre-alpha</span>
        </h1>
        <h2 className="slogan">
          Your <span className="primal-gradient">primal</span> way to connect
        </h2>
        <p className="description">
          Cavegram is a social platform where everything is a hand-drawn image.
        </p>
      </div>
      <div className="form-section">
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit} className="signin-form">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" className="signin-button">
            Sign In
          </button>
          <Link to="/reset-password" className="forgot-password-link">
            Forgot Password?
          </Link>
        </form>
        <Link to="/signup" className="signin-form signup-button">
          Create Account
        </Link>
      </div>
    </div>
  );
};

export default SignInForm;
